"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  usePrevious: true
};
Object.defineProperty(exports, "usePrevious", {
  enumerable: true,
  get: function get() {
    return _usePrevious["default"];
  }
});
var _string = require("./string");
Object.keys(_string).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _string[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _string[key];
    }
  });
});
var _validation = require("./validation");
Object.keys(_validation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _validation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _validation[key];
    }
  });
});
var _boostHelpers = require("./boostHelpers");
Object.keys(_boostHelpers).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _boostHelpers[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _boostHelpers[key];
    }
  });
});
var _analytics = require("./analytics");
Object.keys(_analytics).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _analytics[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _analytics[key];
    }
  });
});
var _window = require("./window");
Object.keys(_window).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _window[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _window[key];
    }
  });
});
var _usePrevious = _interopRequireDefault(require("./usePrevious"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }